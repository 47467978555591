import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

import appConfig from '@/app-config'

const firebaseApp = firebase.initializeApp(appConfig.firebase)

//
// Firebase Auth
//

export const auth = firebaseApp.auth()

export type AuthUser = firebase.User

//
// Firestore
//

export const deleteField = firebase.firestore.FieldValue.delete
export const firestore = firebaseApp.firestore()
export const firestoreTimestamp = firebase.firestore.Timestamp
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp
export const arrayUnion = firebase.firestore.FieldValue.arrayUnion

export type FirestoreCollectionRef = firebase.firestore.CollectionReference
export type FirestoreDocument = firebase.firestore.DocumentData
export type FirestoreDocumentRef =
    firebase.firestore.DocumentReference<FirestoreDocument>
export type FirebaseDocumentSnapshot =
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
export type FirestoreQuerySnapshot = firebase.firestore.QuerySnapshot
export type FirestoreTimestamp = firebase.firestore.Timestamp
export type FirestoreWriteBatch = firebase.firestore.WriteBatch

//
// Firebase Storage
//

export const storage = firebaseApp.storage()
export const uploadStateChangedEvent = firebase.storage.TaskEvent.STATE_CHANGED

export type StorageRef = firebase.storage.Reference
export type UploadTask = firebase.storage.UploadTask

//
// Firebase Functions
//

export const functions = firebaseApp.functions()
