import { computed, DeepReadonly, readonly, ref, Ref } from 'vue'

import {
    firestore,
    FirestoreCollectionRef,
    FirestoreDocumentRef,
    FirestoreTimestamp,
    FirestoreWriteBatch,
    serverTimestamp,
} from '@/firebase-config'

import useCourses from '@/composables/global/use-courses'
import useViewer from '@/composables/global/use-viewer'

import { arrayCompare } from '@/utils/arrays'
import { logger } from '@/utils/debug'
import {
    cleanObjForFirestore,
    docData,
    generateDocId,
    getCollectionDocs,
} from '@/utils/firestore'
import { objectEntries } from '@/utils/objects'
import { hasOnlyDigits } from '@/utils/strings'

import {
    MatchOptionsHeaders,
    MediaNarration,
    Question,
    QuestionCategory,
    QuestionCondition,
    QuestionType,
} from '@/models/courses/levels/pages/question'
import {
    Bucket,
    BucketAnswerData,
} from '@/models/courses/levels/pages/questions/bucket'
import { Choice } from '@/models/courses/levels/pages/questions/choice'
import { MatchOption } from '@/models/courses/levels/pages/questions/match-option'
import {
    Answer,
    BucketAnswers,
    MatchOptionAnswers,
} from '@/models/users/answer'

const debug = false

export type ChoiceForm = {
    id: string
    num: number
    text: string
    isCorrect: boolean
}

export type BucketForm = {
    id: string
    num: number
    title?: string
    type: 'text' | 'image'
    text?: string
    image?: { file?: string; title?: string }
    answers: BucketAnswerData[]
}

export type MatchOptionForm = {
    id: string
    num: number
    questionText: string
    answer: { id: string; text: string }
}

export type QuestionForm = {
    id: string
    text: string
    num: number
    type: QuestionType
    scorable: boolean
    summary?: boolean
    condition: QuestionCondition
    category: QuestionCategory
    narration?: MediaNarration
    feedback: string
    feedbackNarration?: MediaNarration
    feedbackCorrect: string
    feedbackCorrectNarration?: MediaNarration
    feedbackIncorrect: string
    feedbackIncorrectNarration?: MediaNarration
    choices?: ChoiceForm[]
    buckets?: BucketForm[]
    matchOptions?: MatchOptionForm[]
    matchOptionsHeaders?: MatchOptionsHeaders
    rangeMin: number
    rangeMax: number
    lowerText: string
    middleText: string
    upperText: string
    correctRangeChoices: number[]
    hasChanges: boolean
    hasError: boolean
}

export type ResponseState = {
    answerId: string | undefined
    question: string
    questionType: QuestionType
    attempt: number
    text: string
    choices?: string[]
    buckets?: string[]
    bucketAnswers?: BucketAnswers
    matchOptionAnswers?: MatchOptionAnswers
    range: number
    scorable: boolean
    isCorrect: boolean
    hasChange: boolean
}

export type QuizItem = {
    question: Question
    choices?: Choice[]
    buckets?: Bucket[]
    matchOptions?: MatchOption[]
    answer?: Answer
}

const quiz: Ref<QuizItem[]> = ref([])
const quizQuestionIds = computed(() => quiz.value.map((i) => i.question.id))

export default function (levelId?: string, pageId?: string) {
    const { activeCourse } = useCourses()
    const { viewer } = useViewer()

    // Computed properties / helper functions
    function areScorableQuestionsAnswered(quizItems: QuizItem[]) {
        logger(debug, quizItems)
        return quizItems
            .filter(
                (item) =>
                    item.question.scorable &&
                    (item.question.condition === 'requireAnswer' ||
                        item.question.condition === 'requireCorrect')
            )
            .every((item) => item.answer)
    }

    const hasAnsweredAtLeastOneQuestion = computed(() =>
        quiz.value.some((obj) => {
            const isAnswered = obj.answer !== undefined
            return isAnswered
        })
    )

    const hasAllRequiredQuestionsAnswered = computed(() =>
        quiz.value.every((obj) => {
            const isAnswered = obj.answer !== undefined
            switch (obj.question.condition) {
                case 'requireAnswer':
                case 'requireCorrect':
                    return isAnswered
                default:
                    return true
            }
        })
    )

    const isForwardEnabled = computed(() =>
        quiz.value.every((obj) => {
            const isAnswered = obj.answer !== undefined
            switch (obj.question.condition) {
                case 'requireCorrect': {
                    const isCorrect = obj.answer?.isCorrect
                    return isAnswered && isCorrect
                }
                case 'requireAnswer':
                    return isAnswered
                default:
                    return true
            }
        })
    )

    function spreadBucketAnswers(
        bucketAnswers: DeepReadonly<BucketAnswers> | undefined
    ): BucketAnswers | undefined {
        if (!bucketAnswers) return {}
        let returnObj = {}
        objectEntries(bucketAnswers).forEach((entry) => {
            const id = entry[0]
            const answerIds = entry[1]
            returnObj = {
                ...returnObj,
                [id]: [...answerIds],
            }
        })
        return returnObj
    }

    function verifyBucketAnswerData(
        buckets: DeepReadonly<Bucket[]> | undefined,
        answer: DeepReadonly<Answer> | undefined
    ) {
        if (!buckets || !answer) return false

        const answerBucketAnswers = spreadBucketAnswers(answer?.bucketAnswers)
        if (!answerBucketAnswers || !Object.keys(answerBucketAnswers).length)
            return false

        let result = true

        const questionBucketsLength = buckets?.reduce(
            (totalLength, bucket) => totalLength + bucket.answers.length,
            0
        )
        //check bucket length
        if (Object.keys(answerBucketAnswers).length !== buckets?.length)
            result = false

        let answerBucketsLength = 0

        objectEntries(answerBucketAnswers).forEach((entry) => {
            const id = entry[0]
            const answerIds = entry[1]

            answerBucketsLength += answerIds.length

            //check answer's id matches bucket id
            const questionBucket = buckets?.find((bucket) => bucket.id === id)
            if (!questionBucket) result = false

            //check if id of each answer value matches a bucket answer id
            answerIds.forEach((answerId) => {
                if (
                    !buckets
                        ?.flatMap((bucket) => bucket.answers)
                        .find((answer) => answer.id === answerId)
                )
                    result = false
            })
        })
        //check buckets' answers length and answers' buckets' answers length
        if (questionBucketsLength !== answerBucketsLength) result = false

        return result
    }

    function verifyMatchOptionAnswerData(
        matchOptions: DeepReadonly<MatchOption[]> | undefined,
        answer: DeepReadonly<Answer> | undefined
    ) {
        if (!matchOptions || !answer) return false

        const answerMatchOptionAnswers = answer?.matchOptionAnswers

        if (
            !answerMatchOptionAnswers ||
            !Object.keys(answerMatchOptionAnswers).length
        )
            return false

        if (
            Object.keys(answerMatchOptionAnswers).length !== matchOptions.length
        )
            return false

        let result = true

        objectEntries(answerMatchOptionAnswers).forEach((entry) => {
            const questionId = entry[0]
            const answerId = entry[1]

            const questionMatchOption = matchOptions.find(
                (matchOption) => matchOption.id === questionId
            )
            if (!questionMatchOption) result = false

            if (
                !matchOptions.find(
                    (matchOption) => matchOption.answer.id === answerId
                )
            )
                result = false
        })

        return result
    }

    function isQuestionConditionMet(
        condition: string,
        answer: Answer | undefined
    ) {
        logger(debug, condition, answer)
        if (answer === undefined) {
            return false
        }
        if (condition === 'requireCorrect') {
            return answer.isCorrect ?? false
        } else {
            return true
        }
    }

    const isCompleted = computed(() =>
        quiz.value.reduce((acc: { [key: string]: boolean }, obj) => {
            acc[obj.question.id] = isQuestionConditionMet(
                obj.question.condition,
                obj.answer
            )
            return acc
        }, {})
    )

    const isOptionalQuiz = computed(() =>
        quiz.value.every(
            (obj) =>
                obj.question.condition === 'optional' ||
                obj.question.condition === 'recommended'
        )
    )

    const showCorrectMediaFeedback = computed(() => {
        let correctAnswers = 0
        const numberOfQuestions = quiz.value.length
        if (numberOfQuestions === 0 || numberOfQuestions === undefined)
            return false
        quiz.value.forEach((obj) => {
            if (obj.answer?.isCorrect) {
                correctAnswers++
            }
        })
        if (numberOfQuestions === 1 && correctAnswers === 1) return true
        if (correctAnswers / numberOfQuestions > 0.5) return true
        return false
    })

    function getTotalScorableQuestions(quizItems: QuizItem[]) {
        let count = 0
        quizItems.forEach((item) => {
            if (item.question.scorable) count++
        })
        return count
    }

    const totalScorableQuestions = computed(() =>
        getTotalScorableQuestions(quiz.value)
    )

    function getQuizScore(quizItems?: QuizItem[]) {
        logger(debug)

        let score = 0

        ;(quizItems ?? quiz.value).forEach((item) => {
            const questionType = item.question.type
            const scorable = item.question.scorable
            switch (questionType) {
                case 'text': {
                    const hasTextAnswer = !!item.answer?.text
                    if (hasTextAnswer && scorable) score++
                    break
                }
                default: {
                    const hasCorrectAnswer = !!item.answer?.isCorrect
                    if (hasCorrectAnswer && scorable) score++
                    break
                }
            }
        })

        return score
    }

    let questionsCollection: FirestoreCollectionRef | undefined = undefined
    function setQuestionCollection(levelId: string, pageId: string) {
        logger(debug, levelId, pageId)
        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }

        questionsCollection = firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('levels')
            .doc(levelId)
            .collection('pages')
            .doc(pageId)
            .collection('questions')
    }

    if (
        activeCourse.value?.id !== undefined &&
        levelId !== undefined &&
        pageId !== undefined
    ) {
        setQuestionCollection(levelId, pageId)
    }

    function getQuizItemById(id: string) {
        logger(debug, id)
        const index = quizQuestionIds.value.indexOf(id)
        return quiz.value[index]
    }

    function isResponseCorrect(
        response: ResponseState,
        quizItems: DeepReadonly<QuizItem[]>
    ) {
        logger(debug, response)
        if (response.questionType === 'text') return
        const matchingQuizItem = quizItems.find(
            ({ question }) => question.id === response.question
        )
        if (!matchingQuizItem) return

        if (matchingQuizItem.question.type === 'range' && response?.range) {
            return matchingQuizItem.question.correctRangeChoices?.includes(
                response?.range
            )
        } else if (
            (matchingQuizItem.question.type === 'bucket' &&
                response?.buckets) ||
            (matchingQuizItem.question.type === 'match' &&
                response?.matchOptionAnswers)
        ) {
            return response.isCorrect
        } else {
            const correctChoices = matchingQuizItem.choices
                ?.filter((c) => c.isCorrect)
                .map((c) => c.id)

            if (
                response.choices === undefined ||
                correctChoices === undefined ||
                response.choices.length !== correctChoices.length
            ) {
                return false
            } else {
                const equalArrs = arrayCompare(correctChoices, response.choices)
                return equalArrs
            }
        }
    }

    // Fetching

    async function fetchScorableQuestions() {
        logger(debug)
        if (questionsCollection === undefined) throw 'No level page specified'

        const questionsSnapshot = await questionsCollection
            .where('scorable', '==', true)
            .orderBy('num')
            .get()
        if (questionsSnapshot.empty) {
            return undefined
        } else {
            const questionsFound =
                getCollectionDocs<Question>(questionsSnapshot)
            return questionsFound
        }
    }

    async function fetchQuestions() {
        logger(debug)
        if (questionsCollection === undefined) throw 'No level page specified'

        const questionsSnapshot = await questionsCollection.orderBy('num').get()
        if (questionsSnapshot.empty) {
            return undefined
        } else {
            const questionsFound =
                getCollectionDocs<Question>(questionsSnapshot)
            return questionsFound
        }
    }

    async function fetchChoices(questionId: string) {
        logger(debug, questionId)
        if (questionsCollection === undefined) throw 'No level page specified'

        const choicesSnapshot = await questionsCollection
            .doc(questionId)
            .collection('choices')
            .orderBy('num')
            .get()

        if (choicesSnapshot.empty) {
            return undefined
        } else {
            return getCollectionDocs<Choice>(choicesSnapshot)
        }
    }

    async function fetchBuckets(questionId: string) {
        logger(debug, questionId)
        if (questionsCollection === undefined) throw 'No level page specified'

        const bucketsSnapshot = await questionsCollection
            .doc(questionId)
            .collection('buckets')
            .orderBy('num')
            .get()

        if (bucketsSnapshot.empty) {
            return undefined
        } else {
            return getCollectionDocs<Bucket>(bucketsSnapshot)
        }
    }

    async function fetchMatchOptions(questionId: string) {
        logger(debug, questionId)
        if (questionsCollection === undefined) throw 'No level page specified'

        const matchOptionsSnapshot = await questionsCollection
            .doc(questionId)
            .collection('matchOptions')
            .orderBy('num')
            .get()

        if (matchOptionsSnapshot.empty) {
            return undefined
        } else {
            return getCollectionDocs<MatchOption>(matchOptionsSnapshot)
        }
    }

    async function fetchAnswer(questionId: string, userId?: string) {
        logger(debug, questionId)
        const user = userId ?? viewer.value?.id
        if (user === undefined) return undefined

        const answerSnapshot = await firestore
            .collection('users')
            .doc(user)
            .collection('answers')
            .where('question', '==', questionId)
            .orderBy('attempt')
            .limitToLast(1)
            .get()

        if (answerSnapshot.empty) {
            return undefined
        } else {
            return docData<Answer>(answerSnapshot.docs[0])
        }
    }

    // Loading

    async function fetchQuiz(userId?: string) {
        logger(debug)

        const retrievedQuestions = await fetchQuestions()
        if (retrievedQuestions === undefined) {
            logger(debug, 'Page has no questions')
            return []
        }

        const quizItemPromises = retrievedQuestions.map((question) => {
            return Promise.all([
                question,
                fetchChoices(question.id),
                fetchBuckets(question.id),
                fetchMatchOptions(question.id),
                fetchAnswer(question.id, userId),
            ])
        })
        const quizItemsData = await Promise.all(quizItemPromises)
        return quizItemsData.map(
            ([question, choices, buckets, matchOptions, answer]) => ({
                question,
                ...(choices && { choices }),
                ...(buckets && { buckets }),
                ...(matchOptions && { matchOptions }),
                ...(answer && { answer }),
            })
        )
    }

    async function loadQuiz(userId?: string) {
        logger(debug)
        quiz.value = await fetchQuiz(userId)
    }

    // CRUD operations

    function addAnswers(
        responses: ResponseState[],
        quizItems: DeepReadonly<QuizItem[]>
    ) {
        logger(debug, responses, quizItems)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const answersCollection = firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('answers')

        const batch = firestore.batch()

        responses
            .filter((response) => response.hasChange)
            .forEach((response) => {
                const newAnswerDoc = answersCollection.doc()
                const isCorrect =
                    isResponseCorrect(response, quizItems) ?? false
                let data: Answer = {
                    id: newAnswerDoc.id,
                    question: response.question,
                    attempt: response.attempt + 1,
                    createdAt: serverTimestamp() as FirestoreTimestamp,
                }
                switch (response.questionType) {
                    case 'text': {
                        data = { ...data, text: response.text }
                        break
                    }
                    case 'range': {
                        data = { ...data, isCorrect, range: response.range }
                        break
                    }
                    case 'match': {
                        data = {
                            ...data,
                            isCorrect,
                            matchOptionAnswers: response.matchOptionAnswers,
                        }
                        break
                    }
                    case 'bucket': {
                        data = {
                            ...data,
                            isCorrect,
                            bucketAnswers: response.bucketAnswers,
                        }
                        break
                    }
                    default: {
                        data = { ...data, isCorrect, choices: response.choices }
                        break
                    }
                }
                batch.set(newAnswerDoc, data)
            })

        return batch.commit()
    }

    async function deleteQuizAnswer(questionId: string, userId: string) {
        logger(debug, questionId, userId)
        if (userId === undefined) return

        const querySnapshot = await firestore
            .collection('users')
            .doc(userId)
            .collection('answers')
            .where('question', '==', questionId)
            .get()

        const batch = firestore.batch()

        querySnapshot.forEach((doc) => {
            batch.delete(doc.ref)
        })

        await batch.commit()
    }

    function batchSetChoice(
        choiceForm: ChoiceForm,
        questionDoc: FirestoreDocumentRef,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, choiceForm)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const isNewChoice = hasOnlyDigits(choiceForm.id)
        const choiceDoc = isNewChoice
            ? questionDoc.collection('choices').doc()
            : questionDoc.collection('choices').doc(choiceForm.id)

        batch.set(
            choiceDoc,
            {
                num: choiceForm.num,
                isCorrect: choiceForm.isCorrect,
                text: choiceForm.text,
                ...(isNewChoice
                    ? { createdAt: serverTimestamp() }
                    : { updatedAt: serverTimestamp() }),
                updatedBy: viewer.value.id,
            },
            { merge: true }
        )
    }

    function batchSetBucket(
        bucketForm: BucketForm,
        questionDoc: FirestoreDocumentRef,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, bucketForm)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const isNewBucket = hasOnlyDigits(bucketForm.id)
        const bucketFormDoc = isNewBucket
            ? questionDoc.collection('buckets').doc()
            : questionDoc.collection('buckets').doc(bucketForm.id)

        batch.set(bucketFormDoc, {
            ...cleanObjForFirestore('set', bucketForm),
            ...(isNewBucket
                ? { createdAt: serverTimestamp() }
                : { updatedAt: serverTimestamp() }),
            updatedBy: viewer.value.id,
        })
    }

    function batchSetMatchOption(
        matchOptionForm: MatchOptionForm,
        questionDoc: FirestoreDocumentRef,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, matchOptionForm)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const isNewMatchOption = hasOnlyDigits(matchOptionForm.id)
        const matchOptionDoc = isNewMatchOption
            ? questionDoc.collection('matchOptions').doc()
            : questionDoc.collection('matchOptions').doc(matchOptionForm.id)

        batch.set(
            matchOptionDoc,
            {
                num: matchOptionForm.num,
                questionText: matchOptionForm.questionText,
                answer: matchOptionForm.answer,
                ...(isNewMatchOption
                    ? { createdAt: serverTimestamp() }
                    : { updatedAt: serverTimestamp() }),
                updatedBy: viewer.value.id,
            },
            { merge: true }
        )
    }

    function batchDeleteChoice(
        choiceId: string,
        questionDoc: FirestoreDocumentRef,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, choiceId)

        const choiceDoc = questionDoc.collection('choices').doc(choiceId)
        batch.delete(choiceDoc)
    }

    function batchDeleteBucket(
        bucketId: string,
        questionDoc: FirestoreDocumentRef,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, bucketId)

        const bucketDoc = questionDoc.collection('buckets').doc(bucketId)
        batch.delete(bucketDoc)
    }

    function batchDeleteMatchOption(
        matchOptionId: string,
        questionDoc: FirestoreDocumentRef,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, matchOptionId)

        const matchOptionDoc = questionDoc
            .collection('matchOptions')
            .doc(matchOptionId)
        batch.delete(matchOptionDoc)
    }

    function batchSetQuestion(
        questionForm: QuestionForm,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, questionForm)
        if (questionsCollection === undefined) {
            throw 'No level page specified'
        }
        if (viewer.value === undefined) {
            throw 'Viewer not initialized'
        }

        const quizItem = getQuizItemById(questionForm.id)

        const isNewQuestion = quizItem === undefined
        const questionDoc = questionsCollection.doc(questionForm.id)
        const data = {
            ...cleanObjForFirestore('set', questionForm, {
                deleteFields: ['buckets', 'choices', 'hasError', 'hasChanges'],
            }),
            ...(isNewQuestion
                ? { createdAt: serverTimestamp() }
                : { updatedAt: serverTimestamp() }),

            updatedBy: viewer.value.id,
        }
        batch.set(questionDoc, data, { merge: true })

        questionForm.choices?.forEach((c) => {
            batchSetChoice(c, questionDoc, batch)
        })

        questionForm.buckets?.forEach((bucket) => {
            batchSetBucket(bucket, questionDoc, batch)
        })

        questionForm.matchOptions?.forEach((option) => {
            batchSetMatchOption(option, questionDoc, batch)
        })

        const formChoiceIds = questionForm.choices?.map((c) => c.id)

        const formMatchOptionIds = questionForm.matchOptions?.map(
            (matchOption) => matchOption.id
        )

        const deletedChoices = quizItem?.choices
            ?.map((choice) => choice.id)
            .filter((id) => !formChoiceIds?.includes(id))

        deletedChoices?.forEach((id) =>
            batchDeleteChoice(id, questionDoc, batch)
        )

        const formBucketIds = questionForm.buckets?.map((bucket) => bucket.id)

        const deletedBuckets = quizItem?.buckets
            ?.map((bucket) => bucket.id)
            .filter((id) => !formBucketIds?.includes(id))

        deletedBuckets?.forEach((id) =>
            batchDeleteBucket(id, questionDoc, batch)
        )

        const deletedMatchOptions = quizItem?.matchOptions
            ?.map((matchOption) => matchOption.id)
            .filter((id) => !formMatchOptionIds?.includes(id))

        deletedMatchOptions?.forEach((id) =>
            batchDeleteMatchOption(id, questionDoc, batch)
        )
    }

    function batchDeleteQuestion(
        questionId: string,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, questionId)
        if (questionsCollection === undefined) {
            throw 'No level page specified'
        }

        const questionDoc = questionsCollection.doc(questionId)
        const quizItem = getQuizItemById(questionId)

        const deletedChoices = quizItem?.choices?.map((choice) => choice.id)

        deletedChoices?.forEach((id) => {
            batchDeleteChoice(id, questionDoc, batch)
        })

        const deletedBuckets = quizItem.buckets?.map((bucket) => bucket.id)

        deletedBuckets?.forEach((id) => {
            batchDeleteBucket(id, questionDoc, batch)
        })

        const deletedMatchOptions = quizItem?.matchOptions?.map(
            (matchOption) => matchOption.id
        )

        deletedMatchOptions?.forEach((id) => {
            batchDeleteMatchOption(id, questionDoc, batch)
        })

        batch.delete(questionDoc)
    }

    function batchUpdateQuiz(
        forms: QuestionForm[],
        batch: FirestoreWriteBatch
    ) {
        logger(debug, forms)

        forms
            .sort((a, b) => a.num - b.num)
            .forEach((q, index) => {
                batchSetQuestion({ ...q, num: index + 1 }, batch)
            })

        const formQuestionIds = forms.map((form) => form.id)
        const deletedQuestions = quiz.value
            .map((item) => item.question.id)
            .filter((id) => !formQuestionIds.includes(id))

        deletedQuestions.forEach((questionId) =>
            batchDeleteQuestion(questionId, batch)
        )
    }

    // Others

    function generateChoiceId(questionId: string) {
        logger(debug)
        return generateDocId(
            questionsCollection?.doc(questionId).collection('choices')
        )
    }

    function generateQuestionId() {
        logger(debug)
        return generateDocId(questionsCollection)
    }

    function deinitQuiz() {
        logger(debug)
        quiz.value = []
    }

    return {
        quiz: readonly(quiz),
        hasAnsweredAtLeastOneQuestion,
        hasAllRequiredQuestionsAnswered,
        isForwardEnabled,
        isCompleted,
        isOptionalQuiz,
        showCorrectMediaFeedback,
        totalScorableQuestions,

        addAnswers,
        batchUpdateQuiz,

        areScorableQuestionsAnswered,
        deinitQuiz,
        deleteQuizAnswer,
        fetchAnswer,
        fetchChoices,
        fetchMatchOptions,
        fetchScorableQuestions,
        fetchQuestions,
        generateChoiceId,
        fetchQuiz,
        generateQuestionId,
        getQuizScore,
        getTotalScorableQuestions,
        isResponseCorrect,
        loadQuiz,
        setQuestionCollection,
        spreadBucketAnswers,
        verifyBucketAnswerData,
        verifyMatchOptionAnswerData,
    }
}
