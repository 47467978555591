export type VoiceResponseQuestionData = {
    id: string
    questionEn: string
    questionEs: string
    moduleNumber: number
}

export const voiceResponseQuestions: VoiceResponseQuestionData[] = [
    {
        id: 'voiceResponseQuestion1',
        questionEn:
            'How do you help yourself wait for playtime with your parents?',
        questionEs:
            '¿Cómo te ayudas a esperar la hora de jugar con tus padres?',
        moduleNumber: 1,
    },
    {
        id: 'voiceResponseQuestion2',
        questionEn: 'How can you let your parents know what you want to play?',
        questionEs: '¿Cómo puedes decirle a tus padres lo que quieres jugar?',
        moduleNumber: 1,
    },
    {
        id: 'voiceResponseQuestion3',
        questionEn: 'How do you feel when you get to choose what to play?',
        questionEs: '¿Cómo te sientes cuando puedes elegir qué jugar?',
        moduleNumber: 1,
    },
    {
        id: 'voiceResponseQuestion4',
        questionEn: 'What are you proud of?',
        questionEs: '¿De qué estás orgulloso?',
        moduleNumber: 2,
    },
    {
        id: 'voiceResponseQuestion5',
        questionEn: 'What are you worried about?',
        questionEs: '¿De qué estás preocupado?',
        moduleNumber: 3,
    },
    {
        id: 'voiceResponseQuestion6',
        questionEn:
            'What are you struggling with? Why? How can you overcome it? What are you doing to overcome it?',
        questionEs: '¿Con qué estás luchando?',
        moduleNumber: 4,
    },
]
