import { createI18n } from 'vue-i18n'

export default createI18n({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        // For values that need to be defined outside a component's setup method
        en: {
            Call: {
                recordingCall: 'This call is being recorded',
            },
            Chats: {
                today: 'Today',
                yesterday: 'Yesterday',
            },
            FileSystem: {
                downloadingFile: 'Downloading file',
                error: 'Unable to save file',
                fileDownloaded: 'File downloaded',
            },
            GuideSession: {
                answeredCall: {
                    yes: 'Yes',
                    yesScheduling: 'Yes, scheduling',
                    sentTextMessage: 'Sent text message',
                    noLeftMessage: 'No, left message',
                    no: 'No',
                },
                intTopicItems: {
                    miIntro: 'MI: Intro',
                    miGoals: 'MI: Goals',
                    miCommittment: 'MI: Commitment',
                    miAmbivalence: 'MI: Addressing ambivalence',
                    priorEfforts: 'Prior efforts to improve mood',
                    moodTriangle: 'Mood triangle',
                    moodSpirals: 'Mood spirals',
                    trackingMood: 'Tracking mood',
                    understandDepression: 'Understanding depression',
                    pleasantActivities: 'Pleasant activities',
                    activityTracking: 'Activity tracking',
                    makingPlan: 'Making a plan',
                    reverseSpiral: 'Reverse a downward spiral',
                    mindfulness: 'Mindfulness',
                    mindfulnessPractice: 'Mindfulness practice',
                    childPleasantActivities: 'Child Pleasant Activities',
                    childPalBarriers: 'Barriers (Child PAL)',
                    playStrategies: 'Play strategies',
                    playTypes: 'Types of play',
                    savoring: 'Savoring',
                    anticipation: 'Anticipation',
                    attentionToUpsettingThoughts:
                        'Attention to upsetting thoughts',
                    upsettingThoughtTypes: 'Types of upsetting thoughts',
                    identifyUpsettingThoughts: 'Identifying upsetting thoughts',
                    upsettingThoughtResponse:
                        'Responding to upsetting thoughts',
                    maintenanceSkills: 'Using maintenance skills',
                    warningSigns: 'Early warning signs',
                    identifyMaintenanceSkills: 'Identify maintenance skills',
                    safetyPlanning: 'Safety planning',
                    miDisengagement: 'MI: Disengagement',
                    lackOfBelief: 'Lack of belief in themselves',
                    momnetConcerns: 'Concerns about the Mom-Net Program',
                    goalsForChange: 'Goals For Change',
                    possibleBarriersAndPlans:
                        'Possible Barriers and Plans to Address',
                    establishRapport: 'Establish rapport',
                    describeMomNet: 'How mom is feeling',
                    describeCoachCalls: 'Hopes and prior efforts',
                    mothersGoals: 'Barriers and possible solutions',
                    reviewProgress: 'Review progress in Mom-Net Program',
                    reviewMoodAndActivity:
                        'Review progress in mood and activity ratings',
                    engagementCheckin: 'Mood and engagement check-in',
                    affirmSignsOfEffort:
                        'Affirm Mom-Net Practice & Signs of Good Effort',
                    plansForFuture:
                        'Make a plan for future use of Mom-Net strategies',
                    continuousEngagement:
                        'Invitation to keep engaging with Mom-Net sessions and tools',
                    safetyReview: 'Safety Review',
                    prompt: {
                        participantChangeImpactViaMomNet:
                            'What participant wants to see change via Mom-Net and how that would affect their life',
                        participantFeedback:
                            'Include participant reaction to content, and any ideas for what they want to work on and barriers identified',
                        participantConcernsMomNet:
                            "Participant's concerns about participating in Mom-Net",
                    },
                },
                highIntSessionLabels: [
                    'MI',
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8',
                    'Disengagement Script',
                ],
                lowIntSessionLabels: [
                    'Call 1 (motivation)',
                    'Call 2',
                    'Call 3',
                    'Call 4',
                    'Disengagement',
                ],
            },
            PushNotifications: {
                error: 'Unable to receive push notifications',
                newMessage: 'New message from {user}',
                incomingCall: 'Incoming call from {user}',
            },
            Reminders: {
                repeatIntervals: ['Never', 'Day', 'Week', 'Two weeks', 'Month'],
            },
            SideMenu: {
                agencies: 'Agency | Agencies',
                activities: 'Pleasant Activities',
                conversationBank: 'Conversation Bank',
                brushTool: 'Brush Together',
                chats: 'Chat',
                classrooms: 'Classroom | Classrooms',
                courses: 'Courses',
                guides: 'Guide | Guides',
                firstStepOrganizer: 'First Step Organizer',
                home: 'Home',
                levels: 'Level | Levels',
                moodRatings: 'Mood Ratings',
                plan: 'Plan',
                resources: 'Resources',
                reminders: 'Reminders',
                schools: 'School | Schools',
                users: 'Users',
                videos: 'Videos',
            },
            TextToSpeech: {
                screenReaderUnavailable:
                    'Screen reader is disabled or unavailable on your device',
            },
            User: {
                userExistsError:
                    '{userId} already exists, edit the user to add a new course or guide',
            },
            UserAgreements: {
                privacyLabel: 'Privacy Agreement',
                eulaLabel: 'End User License Agreement',
            },
            Validations: {
                email: 'invalid email format',
                maxArray: 'choose at most {max}',
                minArray: 'choose at least {min}',
                minDate: 'must be in the future',
                maxNumber: 'max {max}',
                minNumber: 'min {min}',
                maxString: 'max {max} characters',
                minString: 'min {min} characters',
                oneOf: 'must choose one',
                required: 'required',
            },
        },
        es: {
            Call: {
                recordingCall: 'Esta llamada esta siendo grabada',
            },
            Chats: {
                today: 'hoy',
                yesterday: 'ayer',
            },
            FileSystem: {
                error: 'No se puede guardar el archivo',
                fileDownloaded: 'Archivo descargado',
                downloadingFile: 'Descargando archivo',
            },
            GuideSession: {
                answeredCall: {
                    yes: 'Sí',
                    yesScheduling: 'Sí, está programado',
                    sentTextMessage: 'Envié un mensaje de texto',
                    noLeftMessage: 'No, dejé un mensaje de voz',
                    no: 'No',
                },
                intTopicItems: {
                    miIntro: 'EM (Entrevista motivacional): Introducción',
                    miGoals: 'EM: Metas',
                    miCommittment: 'EM: Compromiso',
                    miAmbivalence: 'EM: abordar la ambivalencia',
                    priorEfforts:
                        'Esfuerzos previos para mejorar el estado de ánimo',
                    moodTriangle: 'Triángulo del ánimo',
                    moodSpirals: 'Espirales del estado de ánimo',
                    trackingMood: 'Seguimiento de estados de ánimo',
                    understandDepression: 'Entendiendo la depresión',
                    pleasantActivities: 'Actividades agradables',
                    activityTracking: 'Diario de actividades',
                    makingPlan: 'Haciendo un plan',
                    reverseSpiral: 'Revertir una espiral descendente',
                    mindfulness: 'Atención plena',
                    mindfulnessPractice: 'Práctica de atención plena',
                    childPleasantActivities:
                        'Actividades agradables para niños',
                    childPalBarriers: 'Barreras (Niño PAL)',
                    playStrategies: 'Estrategias de juego',
                    playTypes: 'Tipos de juego',
                    savoring: 'Saboreando',
                    anticipation: 'Anticipación',
                    attentionToUpsettingThoughts:
                        'Atención a los pensamientos inquietantes',
                    upsettingThoughtTypes: 'Tipos de pensamientos inquietantes',
                    identifyUpsettingThoughts:
                        'Identificar pensamientos inquietantes',
                    upsettingThoughtResponse:
                        'Responder a pensamientos inquietantes',
                    maintenanceSkills: 'Usando habilidades de mantenimiento',
                    warningSigns: 'Señales de alerta temprana',
                    identifyMaintenanceSkills:
                        'Identificar habilidades de mantenimiento',
                    safetyPlanning: 'Planificación de seguridad',
                    miDisengagement: 'EM: Desconexión',
                    lackOfBelief: 'Falta de confianza en sí mismos',
                    momnetConcerns: 'Preocupaciones sobre el programa Mom-Net',
                    goalsForChange: 'Metas para el cambio',
                    possibleBarriersAndPlans:
                        'Posibles barreras y planes para abordar',

                    establishRapport: 'Establecer una buena relación',
                    describeMomNet: 'Como se siente la mamá',
                    describeCoachCalls: 'Esperanzas y esfuerzos previos',
                    mothersGoals: 'Barreras y posibles soluciones',
                    reviewProgress:
                        'Revisar el progreso en el Programa Mom-Net',
                    reviewMoodAndActivity:
                        'Revisar el progreso en las calificaciones de estado de ánimo y actividad',
                    engagementCheckin:
                        'Registro de estado de ánimo y compromiso',
                    affirmSignsOfEffort:
                        'Afirme la práctica de Mom-Net y las señales de buen esfuerz',
                    plansForFuture:
                        'Haga un plan para el uso futuro de las estrategias de Mom-Net',
                    continuousEngagement:
                        'Invitación a seguir interactuando con las sesiones y herramientas de Mom-Net',
                    safetyReview: 'Revisión de seguridad',
                    prompt: {
                        participantChangeImpactViaMomNet:
                            '¿Qué quiere que cambie el participante a través de Mom-Net y cómo afectaría eso a su vida?',
                        participantFeedback:
                            'Incluya la reacción del participante al contenido y cualquier idea sobre lo que quiere trabajar y las barreras identificadas',
                        participantConcernsMomNet:
                            'Preocupaciones del participante sobre la participación en Mom-Net',
                    },
                },
                highIntSessionLabels: [
                    'Entrevista Motivacional',
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8',
                    'Guión de desconexión',
                ],
                lowIntSessionLabels: [
                    'Llamada 1 (motivación)',
                    'Llamar 2',
                    'Llamar 3',
                    'Llamar 4',
                    'Desconexión',
                ],
            },
            PushNotifications: {
                error: 'No se pueden recibir notificaciones automáticas',
                newMessage: 'Nuevo mensaje de {user}',
                incomingCall: 'Llamada entrante de {user}',
            },
            Reminders: {
                repeatIntervals: [
                    'nunca',
                    'día',
                    'semana',
                    'dos semanas',
                    'mes',
                ],
            },
            SideMenu: {
                agencies: 'Agencia | Agencias',
                activities: 'Actividades Agradables',
                classrooms: 'Salón | Salones',
                conversationBank: 'Banco de Conversaciones',
                brushTool: 'Brush Together (es)',
                chats: 'Chat',
                courses: 'Cursos',
                firstStepOrganizer: 'First Step Organizer (es)', // TODO
                guides: 'Guía | Guías',
                home: 'Inicio',
                levels: 'Nivel | Niveles',
                moodRatings: 'Calificación de Estados de Ánimo',
                plan: 'Plan (es)',
                resources: 'Fuentes',
                reminders: 'Recordatorios',
                schools: 'Escuela | Escuelas',
                users: 'Usuarios',
                videos: 'Videos',
            },
            TextToSpeech: {
                screenReaderUnavailable:
                    'Screen reader is disabled or unavailable on your device (es)',
            },
            User: {
                userExistsError: '{userId} ya existe',
            },
            UserAgreements: {
                privacyLabel: 'Acuerdo de Privacidad',
                eulaLabel: 'Contrato de Licencia de Usuario Final',
            },
            Validations: {
                email: 'formato de correo inválido',
                maxArray: 'elige como máximo {max}',
                minArray: 'elige al menos {min}',
                minDate: 'debe ser en el futuro',
                maxNumber: 'máximo {max}',
                minNumber: 'menos {min}',
                maxString: 'máximo {max} caracteres',
                minString: 'mínimo {min} caracteres',
                oneOf: 'debe elegir uno',
                required: 'requerida',
            },
        },
    },
})
